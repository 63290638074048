/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable plugin/no-unsupported-browser-features */
/* stylelint-enable plugin/no-unsupported-browser-features */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
div.awsui_column-layout_vj6p7_v7xwq_99:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-pa3mqb, 14px);
  line-height: var(--line-height-body-m-2zx78l, 22px);
  color: var(--color-text-body-default-ffdwgg, #16191f);
  font-weight: 400;
  font-family: var(--font-family-base-4om3hr, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134:not(#\9) {
  margin-block: calc(var(--space-grid-gutter-fzqbbi, 20px) / -2);
  margin-inline: calc(var(--space-grid-gutter-fzqbbi, 20px) / -2);
  /* stylelint-disable-next-line selector-max-universal */
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-no-gutters_vj6p7_v7xwq_140:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144:not(#\9) {
  /* stylelint-disable-next-line selector-max-universal */
  margin-block: calc(-1 * var(--space-grid-gutter-fzqbbi, 20px) / 2);
  margin-inline: calc(-1 * var(--space-grid-gutter-fzqbbi, 20px));
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144 > *:not(#\9) {
  border-inline-start: var(--border-divider-section-width-wl9k66, 1px) solid var(--color-border-divider-default-in8dkg, #eaeded);
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144.awsui_grid-breakpoint-default_vj6p7_v7xwq_152 > *:not(#\9):nth-child(1n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144.awsui_grid-columns-1_vj6p7_v7xwq_155.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-child(1n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144.awsui_grid-columns-1_vj6p7_v7xwq_155.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-child(1n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144.awsui_grid-columns-2_vj6p7_v7xwq_161.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-child(2n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144.awsui_grid-columns-2_vj6p7_v7xwq_161.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-child(2n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144.awsui_grid-columns-3_vj6p7_v7xwq_167.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-child(2n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144.awsui_grid-columns-3_vj6p7_v7xwq_167.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-child(3n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-child(2n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-variant-text-grid_vj6p7_v7xwq_144.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-child(4n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134:not(#\9):not(.awsui_grid-no-gutters_vj6p7_v7xwq_140) > * {
  padding-block: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  padding-inline: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134:not(#\9):not(.awsui_grid-no-gutters_vj6p7_v7xwq_140).awsui_grid-variant-text-grid_vj6p7_v7xwq_144 > * {
  padding-block: 0;
  padding-inline: var(--space-grid-gutter-fzqbbi, 20px);
  margin-block: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  margin-inline: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189:not(#\9) {
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189 > *:not(#\9) {
  border-inline-end: var(--border-divider-section-width-wl9k66, 1px) solid var(--color-border-divider-default-in8dkg, #eaeded);
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189.awsui_grid-breakpoint-default_vj6p7_v7xwq_152 > *:not(#\9):nth-child(1n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189.awsui_grid-columns-1_vj6p7_v7xwq_155.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-child(1n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189.awsui_grid-columns-1_vj6p7_v7xwq_155.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-child(1n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189.awsui_grid-columns-2_vj6p7_v7xwq_161.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-child(2n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189.awsui_grid-columns-2_vj6p7_v7xwq_161.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-child(2n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189.awsui_grid-columns-3_vj6p7_v7xwq_167.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-child(2n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189.awsui_grid-columns-3_vj6p7_v7xwq_167.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-child(3n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-child(2n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-vertical-borders_vj6p7_v7xwq_189.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-child(4n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222:not(#\9) {
  /* stylelint-disable selector-max-universal */
  /* stylelint-enable selector-max-universal */
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222 > *:not(#\9) {
  border-block-end: var(--border-divider-section-width-wl9k66, 1px) solid var(--color-border-divider-default-in8dkg, #eaeded);
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222 > *:not(#\9):last-child {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-2_vj6p7_v7xwq_161.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-2_vj6p7_v7xwq_161.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-3_vj6p7_v7xwq_167.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-3_vj6p7_v7xwq_167.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(2):nth-child(3n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-3_vj6p7_v7xwq_167.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(2):nth-child(3n+2) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-3_vj6p7_v7xwq_167.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(3):nth-child(3n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xxs_vj6p7_v7xwq_155 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(2):nth-child(4n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(2):nth-child(4n+2) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(2):nth-child(4n+3) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(3):nth-child(4n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(3):nth-child(4n+2) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vj6p7_v7xwq_99 > .awsui_grid_vj6p7_v7xwq_134.awsui_grid-horizontal-borders_vj6p7_v7xwq_222.awsui_grid-columns-4_vj6p7_v7xwq_173.awsui_grid-breakpoint-xs_vj6p7_v7xwq_158 > *:not(#\9):nth-last-child(4):nth-child(4n+1) {
  border-block-end-width: 0;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_vj6p7_v7xwq_276:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-pa3mqb, 14px);
  line-height: var(--line-height-body-m-2zx78l, 22px);
  color: var(--color-text-body-default-ffdwgg, #16191f);
  font-weight: 400;
  font-family: var(--font-family-base-4om3hr, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  min-inline-size: 0;
  word-break: break-word;
  display: block;
}

.awsui_tile-container_vj6p7_v7xwq_314:not(#\9) {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-block: var(--border-field-width-yoy972, 1px) solid var(--color-border-input-default-aksbt5, #687078);
  border-inline: var(--border-field-width-yoy972, 1px) solid var(--color-border-input-default-aksbt5, #687078);
  border-start-start-radius: var(--border-radius-tiles-cnruhs, 2px);
  border-start-end-radius: var(--border-radius-tiles-cnruhs, 2px);
  border-end-start-radius: var(--border-radius-tiles-cnruhs, 2px);
  border-end-end-radius: var(--border-radius-tiles-cnruhs, 2px);
  background: var(--color-background-input-default-w7mbz6, #ffffff);
  padding-block: var(--space-xs-xf5ch3, 8px);
  padding-inline: var(--space-scaled-m-er48cl, 16px);
  transition: border-color var(--motion-duration-transition-quick-j01tkg, 90ms) var(--motion-easing-transition-quick-9ktw66, linear), background-color var(--motion-duration-transition-quick-j01tkg, 90ms) var(--motion-easing-transition-quick-9ktw66, linear);
}
.awsui_tile-container_vj6p7_v7xwq_314.awsui_refresh_vj6p7_v7xwq_329:not(#\9) {
  padding-block: var(--space-xs-xf5ch3, 8px);
  padding-inline: var(--space-s-2025hl, 12px);
}
.awsui_tile-container_vj6p7_v7xwq_314.awsui_has-metadata_vj6p7_v7xwq_333:not(#\9) {
  padding-block-end: var(--space-s-2025hl, 12px);
}
.awsui_tile-container_vj6p7_v7xwq_314.awsui_has-metadata_vj6p7_v7xwq_333.awsui_refresh_vj6p7_v7xwq_329:not(#\9) {
  padding-block-end: var(--space-scaled-s-9q8n2q, 12px);
}
.awsui_tile-container_vj6p7_v7xwq_314.awsui_selected_vj6p7_v7xwq_339:not(#\9) {
  border-block: var(--border-field-width-yoy972, 1px) solid var(--color-border-item-selected-20gejb, #0073bb);
  border-inline: var(--border-field-width-yoy972, 1px) solid var(--color-border-item-selected-20gejb, #0073bb);
  background: var(--color-background-item-selected-dbqvjr, #f1faff);
}
.awsui_tile-container_vj6p7_v7xwq_314.awsui_disabled_vj6p7_v7xwq_344:not(#\9) {
  border-block: var(--border-field-width-yoy972, 1px) solid var(--color-border-tiles-disabled-o1okbc, transparent);
  border-inline: var(--border-field-width-yoy972, 1px) solid var(--color-border-tiles-disabled-o1okbc, transparent);
  background: var(--color-background-tiles-disabled-ctqo0t, #eaeded);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_tile-container_vj6p7_v7xwq_314:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_tile-container_vj6p7_v7xwq_314:not(#\9), .awsui-mode-entering .awsui_tile-container_vj6p7_v7xwq_314:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_columns_vj6p7_v7xwq_360:not(#\9) {
  display: flex;
  flex-wrap: wrap;
  margin-block: calc(var(--space-grid-gutter-fzqbbi, 20px) / -2);
  margin-inline: calc(var(--space-grid-gutter-fzqbbi, 20px) / -2);
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-1_vj6p7_v7xwq_366 > .awsui_tile-container_vj6p7_v7xwq_314:not(#\9) {
  margin-block: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  margin-inline: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  box-sizing: border-box;
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-1_vj6p7_v7xwq_366 > .awsui_tile-container_vj6p7_v7xwq_314.awsui_breakpoint-xs_vj6p7_v7xwq_374:not(#\9) {
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-1_vj6p7_v7xwq_366 > .awsui_tile-container_vj6p7_v7xwq_314.awsui_breakpoint-xxs_vj6p7_v7xwq_379:not(#\9) {
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-2_vj6p7_v7xwq_384 > .awsui_tile-container_vj6p7_v7xwq_314:not(#\9) {
  margin-block: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  margin-inline: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  box-sizing: border-box;
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-2_vj6p7_v7xwq_384 > .awsui_tile-container_vj6p7_v7xwq_314.awsui_breakpoint-xs_vj6p7_v7xwq_374:not(#\9) {
  flex: 0 0;
  flex-basis: calc(50% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(50% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-2_vj6p7_v7xwq_384 > .awsui_tile-container_vj6p7_v7xwq_314.awsui_breakpoint-xxs_vj6p7_v7xwq_379:not(#\9) {
  flex: 0 0;
  flex-basis: calc(50% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(50% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-3_vj6p7_v7xwq_402 > .awsui_tile-container_vj6p7_v7xwq_314:not(#\9) {
  margin-block: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  margin-inline: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  box-sizing: border-box;
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-3_vj6p7_v7xwq_402 > .awsui_tile-container_vj6p7_v7xwq_314.awsui_breakpoint-xs_vj6p7_v7xwq_374:not(#\9) {
  flex: 0 0;
  flex-basis: calc(33.3333333333% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(33.3333333333% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-3_vj6p7_v7xwq_402 > .awsui_tile-container_vj6p7_v7xwq_314.awsui_breakpoint-xxs_vj6p7_v7xwq_379:not(#\9) {
  flex: 0 0;
  flex-basis: calc(50% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(50% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-4_vj6p7_v7xwq_420 > .awsui_tile-container_vj6p7_v7xwq_314:not(#\9) {
  margin-block: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  margin-inline: calc(var(--space-grid-gutter-fzqbbi, 20px) / 2);
  box-sizing: border-box;
  flex: 0 0;
  flex-basis: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(100% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-4_vj6p7_v7xwq_420 > .awsui_tile-container_vj6p7_v7xwq_314.awsui_breakpoint-xs_vj6p7_v7xwq_374:not(#\9) {
  flex: 0 0;
  flex-basis: calc(25% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(25% - var(--space-grid-gutter-fzqbbi, 20px));
}
.awsui_columns_vj6p7_v7xwq_360.awsui_column-4_vj6p7_v7xwq_420 > .awsui_tile-container_vj6p7_v7xwq_314.awsui_breakpoint-xxs_vj6p7_v7xwq_379:not(#\9) {
  flex: 0 0;
  flex-basis: calc(50% - var(--space-grid-gutter-fzqbbi, 20px));
  max-inline-size: calc(50% - var(--space-grid-gutter-fzqbbi, 20px));
}

.awsui_control_vj6p7_v7xwq_439:not(#\9) {
  flex-grow: 1;
  margin-block-end: var(--space-s-2025hl, 12px);
}
.awsui_control_vj6p7_v7xwq_439.awsui_no-image_vj6p7_v7xwq_443:not(#\9) {
  margin-block-end: 0;
}

.awsui_image_vj6p7_v7xwq_447:not(#\9) {
  text-align: center;
  flex: none;
}
.awsui_image_vj6p7_v7xwq_447 > img:not(#\9) {
  max-inline-size: 100%;
}
.awsui_image_vj6p7_v7xwq_447.awsui_disabled_vj6p7_v7xwq_344 > img:not(#\9) {
  opacity: 0.3;
}